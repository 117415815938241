require('./base/init');
require('bootstrap');

window.inputmask = require('inputmask/dist/jquery.inputmask.min');

require('lightbox2');
require('./base/toggle-enable.js');
require('./base/approve-delete');
require('./base/template');
require('./base/menu-item');
require('../vendor/masks')
require('../vendor/tinymce.min');
require('../vendor/checkSize');
require('.//base/entitySearch');
require('../vendor/lazysizes.min')
require('./base/filesField')


require('../vendor/tinymce.min');
require('../vendor/image-uploader');
require('../vendor/file-manager/js/file-manager');

document.addEventListener('DOMContentLoaded', function () {
    tinymce.init({
        selector: '.textarea_tinymce',
        language: 'ru',
        font_formats: "Muller=muller,sans-serif",
        content_style: "body { font-family: Muller; }",
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table directionality spellchecker',
            'emoticons template paste textpattern image imagetools',
        ],
        toolbar: 'forecolor backcolor | insertfile undo redo | styleselect | fontselect | fontsizeselect | bold italic emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media',
        contextmenu: false,
        relative_urls: false,
        height: 300,
        file_picker_callback(callback, value, meta) {
            let x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
            let y = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight

            console.log(x, y)

            tinymce.activeEditor.windowManager.openUrl({
                url: '/file-manager/tinymce5',
                title: 'Файловый менеджер',
                width: x * 0.8,
                height: y * 0.8,
                onMessage: (api, message) => {
                    callback(message.content, {text: message.text})
                }
            })
        },
    });
})

document.addEventListener('DOMContentLoaded', function () {
    fm.$store.commit('fm/setFileCallBack', function (fileUrl) {
        let modal = $('#file_manager_modal');
        let modalTarget = modal.data('targetSelectedImage');

        $('#' + modalTarget).val(fileUrl);
        $('#selected_' + modalTarget).attr('src', fileUrl);

        modal.modal('hide');
        return false;
    });
});

let imageSelectedItems = document.querySelectorAll(".select_image");
for (let i = 0; i < imageSelectedItems.length; i++) {
    imageSelectedItems[i].addEventListener("click", function (event) {
        $('#file_manager_modal').data('targetSelectedImage', $(this).data('targetSelectedImage')).modal('show');
    }, false);
}

