try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.toastr = require("toastr/toastr");

window.notifySuccess = (message, title) => {
    toastr.success(message, title, {timeOut: 3000});
};

window.notifyWarning = (message, title) => {
    toastr.warning(message, title, {timeOut: 3000});
};

window.notifyError = (message = 'Что-то пошло не так :(', title) => {
    toastr.error(message, title, {timeOut: 3000});
};
